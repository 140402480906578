.color-success {
    background: rgba(0, 128, 0, 0.2) !important;
}
.color-decline {
    background: rgba(255, 0, 0, 0.2) !important;
}

.color-wait_delete {
    background: rgba(253, 163, 62, 0.2) !important;
}

.MuiDataGrid-root .MuiDataGrid-cell{
    /*color: tomato;*/
    white-space: normal !important;
    word-wrap: break-word !important;
}


.color-swapp-1 {
    background: rgba(0, 128, 0, 0.2) !important;
}

.color-lucky-Completed {
    background: rgba(0, 128, 0, 0.2) !important;
}
