.color-3 {
    background: rgba(0, 128, 0, 0.2) !important;
}

.color-1 {
    background: rgba(255, 0, 0, 0.2) !important;
}

.MuiDataGrid-root .MuiDataGrid-cell{
    /*color: tomato;*/
    white-space: normal !important;
    word-wrap: break-word !important;
}